import React, { useState, useContext, useEffect, useCallback } from 'react';
import styles from './styles.module.css';
import ShopServices from '../../../services/ShopServices';
import Loader from '../../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
// import Info from '../../utils/Alert/Info';
// import Error from '../../utils/Alert/Error';
// import Success from '../../utils/Alert/Success';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextField, MenuItem, Button } from '@material-ui/core';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { useParams, Link, useHistory } from 'react-router-dom';
import {
  ROUTES,
  SUBSCRIPTIONS,
  CURRENCIES,
  TERMINAL_TYPES,
} from '../../../const';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import DealerService from '../../../services/DealerServices';

function NewShop() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shopId } = useParams();
  const history = useHistory();

  const getDateOnlyString = (date) => {
    return new Date(date).toISOString().split('T')[0];
  };

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [data, setData] = useState({
    gst_preference: 'Including GST',
    currency: 'RS',
    subscription_end_date: DateTimeHelpers.getOneYearLaterDate(),
    kot_validity: null,
    kot_lite_validity: null,
    image_support_validity: null,
    smart_scale_validity: null,
    expense_validity: null,
    bluetooth_scale_validity: null,
    quickbook_support_validity: null,
    customer_support_validity: null,
    rt_sales_sync_interval: 60,
    additional_sales_balance: 0,
  });
  const [dealers, setDealers] = useState([]);

  const handleAddValidity = (feature) => {
    setData({
      ...data,
      [feature]: DateTimeHelpers.getOneYearLaterDate(data[feature]),
    });
  };

  const handleClearValidity = (feature) => {
    setData({ ...data, [feature]: null });
  };

  const handleSubmit = async () => {
    setLoadingIndicator(true);
    try {
      const shopData = { ...data };

      if (!shopData.dealer_id) {
        postAlertMessage({
          text: 'Dealer is required',
          type: 'failed',
        });
        setLoadingIndicator(false);
        return;
      }

      const keysToTransformDate = [
        'subscription_end_date',
        'kot_validity',
        'kot_lite_validity',
        'image_support_validity',
        'smart_scale_validity',
        'expense_validity',
        'bluetooth_scale_validity',
        'quickbook_support_validity',
        'customer_support_validity',
      ];
      keysToTransformDate.forEach((key) => {
        shopData[key] =
          shopData[key] !== null ? getDateOnlyString(shopData[key]) : null;
      });
      if (shopId) {
        const resp = await ShopServices.updateShop(shopId, shopData);
        setData(resp);
        postAlertMessage({
          text: 'Shop updated successfully',
          type: 'success',
        });
      } else {
        const resp = await ShopServices.createShop(shopData);
        setData(resp);
        postAlertMessage({
          text: 'Shop created successfully',
          type: 'success',
        });
      }
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  const getShop = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const resp = await ShopServices.getShop(shopId);
      if (resp.data?.dealer) {
        resp.data.dealer_id = resp.data.dealer.id;
      }
      setData(resp.data);
    } catch (error) {
      console.error(error);
    }
    setLoadingIndicator(false);
  }, [shopId]);

  const handleCreateTerminal = () => {
    history.push(ROUTES.CREATE_TERMINAL.replace(':shopId', shopId));
  };

  const getDealers = async () => {
    setLoadingIndicator(true);
    try {
      const res = await DealerService.getDealers();
      setDealers(res?.data);
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  };

  useEffect(() => {
    getDealers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shopId) {
      getShop().then();
    }
  }, [shopId, getShop]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Shops<span className={styles.menuTitle}>Management</span>
        </h2>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateTerminal}
        >
          Create Terminal
        </Button>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Shop Details</h2>
          </div>
        </div>
        {data.id && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={12}
              md={3}
              spacing={2}
              justifyContent="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Shop Id</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                disabled
                value={data?.id}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Shop name</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.shop_name}
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Mobile no</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={data?.mobile_no}
              onChange={(e) => {
                setData({ ...data, mobile_no: Number(e.target.value) });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Currency</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              value={data?.currency}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({ ...data, currency: event.target.value });
              }}
            >
              {CURRENCIES.map((currency) => (
                <MenuItem value={currency}>{currency}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {shopId && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={12}
              md={3}
              spacing={2}
              justifyContent="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Ext access token</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={data?.ext_access_token}
                onChange={(e) => {
                  setData({ ...data, ext_access_token: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Subscription</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              name="fromDate"
              size="small"
              value={SUBSCRIPTIONS[data.subscription]}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({
                  ...data,
                  subscription_id: Number(event.target.value),
                });
              }}
            >
              {Object.keys(SUBSCRIPTIONS).map((subType) => (
                <MenuItem key={subType} value={subType}>
                  {SUBSCRIPTIONS[subType]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {shopId && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={12}
              md={3}
              spacing={2}
              justifyContent="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Plu sync today</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={data?.plu}
                onChange={(e) => {
                  setData({ ...data, plu: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Dealer</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              value={data?.dealer_id || ''}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({ ...data, dealer_id: Number(event.target.value) });
              }}
            >
              {dealers.map((dealer) => (
                <MenuItem key={dealer?.id} value={dealer?.id}>
                  {dealer?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {!shopId && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={12}
              md={3}
              spacing={2}
              justifyContent="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Terminal Type</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                select
                size="small"
                defaultValue={TERMINAL_TYPES.pos || ''}
                variant="outlined"
                fullWidth
                color="primary"
                onChange={(event) => {
                  setData({ ...data, terminal_type: event.target.value });
                }}
              >
                {Object.keys(TERMINAL_TYPES).map((terminal_type) => (
                  <MenuItem key={terminal_type} value={terminal_type}>
                    {TERMINAL_TYPES[terminal_type]}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )}
        {!shopId && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={12}
              md={3}
              spacing={2}
              justifyContent="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>MAC ID</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={data?.mac_id}
                onChange={(e) => {
                  setData({ ...data, mac_id: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Subscription end date</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-to"
                    className={styles.dateBox}
                    value={data?.subscription_end_date}
                    onChange={(date) => {
                      setData({
                        ...data,
                        subscription_end_date: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('subscription_end_date')}
                  color="primary"
                  disabled={!data.subscription_end_date}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('subscription_end_date')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Kot validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.kot_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        kot_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('kot_validity')}
                  color="primary"
                  disabled={!data.kot_validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('kot_validity')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Kot lite validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.kot_lite_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        kot_lite_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('kot_lite_validity')}
                  color="primary"
                  disabled={!data.kot_lite_validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('kot_lite_validity')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Image support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.image_support_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        image_support_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('image_support_validity')}
                  color="primary"
                  disabled={!data.image_support_validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddValidity('image_support_validity')}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Quickbook support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.quickbook_support_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        quickbook_support_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() =>
                    handleClearValidity('quickbook_support_validity')
                  }
                  color="primary"
                  disabled={!data.quickbook_support_validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleAddValidity('quickbook_support_validity')
                  }
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Customer support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.customer_support_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        customer_support_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() =>
                    handleClearValidity('customer_support_validity')
                  }
                  color="primary"
                  disabled={!data.customer_support_validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddValidity('customer_support_validity')}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Bluetooth support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.bluetooth_scale_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        bluetooth_scale_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!data.bluetooth_scale_validity}
                  style={{ marginRight: '10px' }}
                  onClick={() =>
                    handleClearValidity('bluetooth_scale_validity')
                  }
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddValidity('bluetooth_scale_validity')}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Smart scale validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.smart_scale_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        smart_scale_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClearValidity('smart_scale_validity')}
                  disabled={!data.smart_scale_validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleAddValidity('smart_scale_validity')}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Expense validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    variant="outlined"
                    format="dd/MM/yyyy"
                    id="date-picker-from"
                    className={styles.dateBox}
                    value={data?.expense_validity}
                    onChange={(date) => {
                      setData({
                        ...data,
                        expense_validity: date,
                      });
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleClearValidity('expense_validity')}
                  disabled={!data.expense_validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('expense_validity')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Rt sales sync interval</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={data?.rt_sales_sync_interval}
              onChange={(e) => {
                setData({ ...data, rt_sales_sync_interval: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Additional Sales Balance</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              value={data?.additional_sales_balance}
              onChange={(e) => {
                setData({ ...data, additional_sales_balance: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Remarks</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.remark}
              onChange={(e) => {
                setData({ ...data, remark: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>User Details</h2>
          </div>
        </div>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>First name</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.first_name}
              onChange={(e) => {
                setData({ ...data, first_name: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Last name</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.second_name}
              onChange={(e) => {
                setData({ ...data, last_name: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>User name</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.username}
              onChange={(e) => {
                setData({ ...data, username: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Password</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="text"
              value={data?.password}
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          className={styles.submitSection}
          justifyContent="flex-end"
        >
          <Link to={ROUTES.SHOPS} className={styles.link}>
            <Button
              variant="contained"
              color="secondary"
              className={styles.closeBtn}
            >
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#00a65a', marginLeft: '10px' }}
            onClick={handleSubmit}
          >
            {data.id ? 'Save Changes' : 'Create Shop'}
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default withConsoleBase(NewShop);

import React, { useState, useContext, useCallback, useEffect } from 'react';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './styles.module.css';
import Loader from '../../utils/Loading';
import { AlertMessagesContext } from 'react-alert-messages';
import { useParams, Link } from 'react-router-dom';
import RegistrationKeyServices from '../../../services/RegistrationKeyServices';
import { Grid, TextField, MenuItem, Button } from '@material-ui/core';
import { SUBSCRIPTIONS, DEALERS, ROUTES } from '../../../const';

function NewRegistrationKey() {
  const history = useHistory();
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { keyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    count: 1,
    remarks: '',
    dealer_id: '',
    group_code: null,
    plans: {
      subscription: { key: 'premium', validity: 365 },
      add_ons: {},
    },
  });

  const handleAddValidity = (feature) => {
    setData({
      ...data,
      plans: {
        ...data.plans,
        add_ons: {
          ...data.plans.add_ons,
          [feature]: { ...data?.plans?.add_ons?.[feature], validity: 365 },
        },
      },
    });
  };

  const handleClearValidity = (feature) => {
    setData({
      ...data,
      plans: {
        ...data.plans,
        add_ons: {
          ...data.plans.add_ons,
          [feature]: { ...data?.plans?.add_ons?.[feature], validity: null },
        },
      },
    });
  };

  const handleChangeValidity = (feature, value) => {
    setData({
      ...data,
      plans: {
        ...data?.plans,
        add_ons: {
          ...data?.plans?.add_ons,
          [feature]: { ...data?.plans?.add_ons?.[feature], ...value },
        },
      },
    });
  };

  const getKey = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await RegistrationKeyServices.getKey(keyId);
      resp.data.plans.add_ons = resp?.data?.plans?.add_ons.reduce(
        (acc, item) => {
          acc[item.key] = item;
          return acc;
        },
        {}
      );
      resp.data.dealer_id = resp.data?.dealer?.id;
      setData(resp.data);
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoading(false);
  }, [keyId, postAlertMessage]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const adonData = Object.keys(data.plans.add_ons).map((key) => ({
        key,
        ...data.plans.add_ons?.[key],
      }));

      const keyData = {
        ...data,
        plans: { ...data.plans, add_ons: adonData },
      };

      if (data.group_code && data.group_code.length > 25) {
        throw Error('Group code must not exceed 25 characters.');
      }

      if (keyId) {
        const resp = await RegistrationKeyServices.updateKey(keyId, keyData);
        setData(resp);
        postAlertMessage({
          text: 'Key updated successfully',
          type: 'success',
        });
      } else {
        const resp = await RegistrationKeyServices.createKey(keyData);
        setData(resp);
        postAlertMessage({
          text: 'Keys created successfully',
          type: 'success',
        });
        history.push(`${ROUTES.REGISTRATION_KEY}?group_code=${resp.data[0]}`);
      }
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (keyId) {
      getKey();
    }
  }, [keyId, getKey]);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loading} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Registration Key<span className={styles.menuTitle}>Management</span>
        </h2>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>Registration Key Details</h2>
          </div>
        </div>
        {!data.id && (
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid
              item
              xs={12}
              md={3}
              spacing={2}
              justifyContent="flex-end"
              className={styles.inputLabelContainer}
            >
              <h3 className={styles.inputLabel}>Number of keys</h3>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="text"
                value={data?.count}
                onChange={(e) => {
                  setData({ ...data, count: Number(e.target.value) });
                }}
              />
            </Grid>
          </Grid>
        )}
        {data.id && (
          <>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={12}
                md={3}
                spacing={2}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Key ID</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  disabled
                  value={data?.id}
                />
              </Grid>
            </Grid>
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid
                item
                xs={12}
                md={3}
                spacing={2}
                justifyContent="flex-end"
                className={styles.inputLabelContainer}
              >
                <h3 className={styles.inputLabel}>Secret</h3>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="text"
                  value={data?.secret}
                  onChange={(e) => {
                    setData({ ...data, secret: e.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Subscription</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              name="fromDate"
              size="small"
              defaultValue={data?.plans?.subscription?.key || ''}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({
                  ...data,
                  plans: {
                    ...data?.plans,
                    subscription: {
                      ...data?.plans?.subscription,
                      key: event.target.value,
                    },
                  },
                });
              }}
            >
              {Object.values(SUBSCRIPTIONS).map((subType) => (
                <MenuItem key={subType} value={subType}>
                  {subType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Dealer</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              select
              size="small"
              defaultValue={DEALERS[data?.dealer_id] || ''}
              variant="outlined"
              fullWidth
              color="primary"
              onChange={(event) => {
                setData({ ...data, dealer_id: Number(event.target.value) });
              }}
            >
              {Object.keys(DEALERS).map((dealer) => (
                <MenuItem key={dealer} value={dealer}>
                  {DEALERS[dealer]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Subscription validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={data?.plans?.subscription?.validity || null}
              onChange={(e) => {
                setData({
                  ...data,
                  plans: {
                    ...data?.plans,
                    subscription: {
                      ...data?.plans?.subscription,
                      validity: Number(e.target.value),
                    },
                  },
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Kot validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data?.plans?.add_ons?.kot?.validity || null}
                  onChange={(e) => {
                    handleChangeValidity('kot', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('kot')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.kot?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('kot')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Kot lite validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data?.plans?.add_ons?.kot_lite?.validity || null}
                  onChange={(e) => {
                    handleChangeValidity('kot_lite', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('kot_lite')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.kot_lite?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('kot_lite')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Image support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data?.plans?.add_ons?.image?.validity || null}
                  onChange={(e) => {
                    handleChangeValidity('image', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('image')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.image?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('image')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Quickbook support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data?.plans?.add_ons?.external_api?.validity || null}
                  onChange={(e) => {
                    handleChangeValidity('external_api', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('external_api')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.external_api?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('external_api')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Customer support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data?.plans?.add_ons?.customer?.validity || null}
                  onChange={(e) => {
                    handleChangeValidity('customer', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('customer')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.customer?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('customer')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Bluetooth support validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={
                    data?.plans?.add_ons?.bluetooth_scale?.validity || null
                  }
                  onChange={(e) => {
                    handleChangeValidity('bluetooth_scale', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('bluetooth_scale')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.bluetooth_scale?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('bluetooth_scale')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Smart scale validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data?.plans?.add_ons?.smart_scale?.validity || null}
                  onChange={(e) => {
                    handleChangeValidity('smart_scale', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>

              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('smart_scale')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.smart_scale?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('smart_scale')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Expense validity</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container>
              <Grid item xs={7} md={8} lg={9}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  type="number"
                  value={data?.plans?.add_ons?.expense?.validity || null}
                  onChange={(e) => {
                    handleChangeValidity('expense', {
                      validity: Number(e.target.value),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={5} md={4} lg={3} className={styles.btnAddYear}>
                <Button
                  variant="contained"
                  onClick={() => handleClearValidity('expense')}
                  color="primary"
                  disabled={!data?.plans?.add_ons?.expense?.validity}
                  style={{ marginRight: '10px' }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleAddValidity('expense')}
                  color="secondary"
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>ECS terminal count</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.plans?.add_ons?.ecs_terminal?.count || null}
              onChange={(e) => {
                handleChangeValidity('ecs_terminal', {
                  count: Number(e.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>POS terminal count</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.plans?.add_ons?.pos_terminal?.count || null}
              onChange={(e) => {
                handleChangeValidity('pos_terminal', {
                  count: Number(e.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Remarks</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.remarks}
              onChange={(e) => {
                setData({ ...data, remarks: e.target.value });
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={styles.inputRow} alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            spacing={2}
            justifyContent="flex-end"
            className={styles.inputLabelContainer}
          >
            <h3 className={styles.inputLabel}>Group Code</h3>
          </Grid>
          <Grid item xs={12} md={9}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={data?.group_code}
              onChange={(e) => {
                setData({ ...data, group_code: e.target.value });
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid
          container
          className={styles.submitSection}
          justifyContent="flex-end"
        >
          <Link to={ROUTES.REGISTRATION_KEY} className={styles.link}>
            <Button
              variant="contained"
              color="secondary"
              className={styles.closeBtn}
            >
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#00a65a', marginLeft: '10px' }}
            onClick={handleSubmit}
          >
            {data.id ? 'Save Changes' : 'Create key'}
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default withConsoleBase(NewRegistrationKey);
